<template>
    <custom-table
        :title="$t('pages.video.title')"
        :subTitle="$t('pages.video.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        :sortItems="sortItems"
        filterComponentName="VideoFilter"
        changeUrlPage
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:information="{ row: record }">
            <div class="d-flex align-items-center">
                <div>
                    <div class="symbol symbol-60px symbol-2by3 flex-shrink-0 me-4 border">
                        <img :src="record.image ? record.image.direct_link : '/media/svg/files/blank-image.svg'" alt="">
                    </div>
                </div>
                <div>
                    <router-link :to="sprintf('/video/detail/%s', [((record.uuid) ? record.uuid : record.id)])" class="fs-5 text-gray-800 text-hover-primary fw-bolder">
                        {{ record.title }}
                    </router-link>
                    <div class="text-gray-400 fw-bold fs-7 my-1">
                        {{ $t('pages.video.cols.group') }}: {{ record.group ? record.group.name : "-" }}
                    </div>
                    <div class="text-gray-400 fw-bold fs-7 my-1">
                        {{ $t('pages.video.cols.extension') }}: {{ record.extension }}
                    </div>
                    <div class="text-gray-400 fw-bold fs-7 my-1">
                        {{ $t('pages.video.cols.owner') }}: {{ record.admin.full_name }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:detail="{ row: record }">
            <div class="text-end">
                <div class="text-gray-800 fw-boldest fs-3">
                    {{ record.duration_text }}
                </div>

                <div class="text-gray-400 fs-7 fw-bold d-block">
                    {{ record.size_detail.text }}
                </div>

                <div class="text-gray-400 fs-7 fw-bold d-block">
                    {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
                </div>
            </div>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="sprintf('/video/detail/%s', [((record.uuid) ? record.uuid : record.id)])" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </router-link>
                <el-tooltip :content="$t('pages.video.images')" placement="left" v-if="record.images_count">
                    <a v-on:click="showRecord(record, 'image')" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen006.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <el-tooltip :content="$t('pages.video.playVideo')" placement="left">
                    <a v-on:click="showRecord(record, 'video')" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/arrows/arr027.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_video" ref="videoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content rounded">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>

                <div class="modal-body py-10 px-lg-17">
                    <div class="d-flex flex-column">
                        <div class="nav-group nav-group-outline mx-auto" data-kt-buttons="true">
                            <button @click="form.showType = 'image'" :class="[form.showType == 'image' && 'active']" class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2">
                                {{ $t('pages.video.images') }}
                            </button>
                            <button @click="form.showType = 'video'" :class="[form.showType == 'video' && 'active']" class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3">
                                {{ $t('pages.video.title') }}
                            </button>
                        </div>

                        <div class="row mt-10" v-if="form.showType == 'image'">
                            <div class="col-md-4 mb-3" v-for="(image, imageIndex) in form.data.images" :key="imageIndex">
                                <div class="symbol symbol-60px symbol-2by3 flex-shrink-0 me-4">
                                    <a :href="image.direct_link" target="_blank">
                                        <img :src="image.direct_link" alt="" class="w-100">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-10" v-if="form.showType == 'video'">
                            <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6" v-if="form.data.streams.length">
                                <li class="nav-item">
                                    <a class="nav-link cursor-pointer" @click="form.videoTab = 'original'" :class="[form.videoTab == 'original' && 'active']">{{ $t('pages.video.videoTab.original') }}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link cursor-pointer" @click="form.videoTab = 'hls'" :class="[form.videoTab == 'hls' && 'active']">{{ $t('pages.video.videoTab.hls') }}</a>
                                </li>
                            </ul>
                            <CustomVideoPlayer v-if="form.videoTab == 'original'" :src="form.data.direct_link"></CustomVideoPlayer>
                            <CustomVideoPlayer v-if="form.videoTab == 'hls'" :src="form.data.streams[0].direct_link"  hlsQualitySelector></CustomVideoPlayer>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button type="reset" class="btn btn-primary" data-bs-dismiss="modal">
                        {{ $t('btn.close')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import CustomVideoPlayer from "@/components/custom-video-player";
export default {
    name: "index",
    components: {
        CustomTable,
        CustomVideoPlayer
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.video.cols.information"),
                    class: "min-w-300px w-100",
                    scopedSlots: {customRender: "information"}
                },
                {
                    name: this.$t("pages.video.cols.detail"),
                    class: "min-w-150px text-end",
                    scopedSlots: {customRender: "detail"},
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            sortItems: [
                {
                    name: this.$t("sortFields.video.titleAsc"),
                    sort: 'title:asc'
                },
                {
                    name: this.$t("sortFields.video.titleDesc"),
                    sort: 'title:desc'
                },
                {
                    name: this.$t("sortFields.video.qualityAsc"),
                    sort: 'quality:asc'
                },
                {
                    name: this.$t("sortFields.video.qualityDesc"),
                    sort: 'quality:desc'
                },
                {
                    name: this.$t("sortFields.video.durationAsc"),
                    sort: 'duration:asc'
                },
                {
                    name: this.$t("sortFields.video.durationDesc"),
                    sort: 'duration:desc'
                },
                {
                    name: this.$t("sortFields.video.sizeAsc"),
                    sort: 'size:asc'
                },
                {
                    name: this.$t("sortFields.video.sizeDesc"),
                    sort: 'size:desc'
                },
                {
                    name: this.$t("sortFields.video.createdAtAsc"),
                    sort: 'created_at:asc'
                },
                {
                    name: this.$t("sortFields.video.createdAtDesc"),
                    sort: 'created_at:desc'
                },
            ],
            form: {
                title: '',
                showType: 'image',
                videoTab: undefined,
                data: {}
            },
        }
    },
    computed: {
        table() {
            return this.$store.state.video.table;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.contentManagement.video.title"), [this.$t("menu.contentManagement.title")]);
        this.$store.dispatch('video/get', {
            page: {},
            filterData: Object.assign(this.$root.getFilterWithUrl({}), {
                sort: 'created_at:desc',
            })
        });
    },
    methods: {
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/video/upload"
                    });
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("video/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'created_at:desc',
                }, filterData)
            });
        },
        refreshTable(){
            this.$store.dispatch("video/refresh");
        },
        deleteRecord(id){
            this.$store.dispatch("video/delete", {
                id: id
            });
        },
        showRecord(record, type){
            this.form.title = record.title;
            this.form.videoTab = 'original';

            this.axios.get(this.endpoint.get('video') + '/' + record.id).then(response => {
                let data = response.data.data;

                this.form.data = data;
                this.form.showType = type;

                let hiddenFunction = () => {
                    this.$refs.videoModal.removeEventListener('hidden.bs.modal', hiddenFunction);
                    this.form.videoTab = undefined;
                };
                this.$refs.videoModal.addEventListener('hidden.bs.modal', hiddenFunction);


                this.showModal(this.$refs.videoModal);
            });
        }
    }
}
</script>

<style>

</style>